import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import { graphql, useStaticQuery } from "gatsby"
import EmblemColor from "../../../svg/emblem_color.svg"
import Typography from "@material-ui/core/Typography"
import Divider from "@material-ui/core/Divider"
import ListItemText from "@material-ui/core/ListItemText"
import excluded from "../../../pagesExcludedFromDynamic.js"
import MLink from "@material-ui/core/Link/Link"
import Drawer from "@material-ui/core/Drawer"
import React from "react"
import { useTheme } from "@material-ui/core"
import styled from "styled-components"
import { Link as _Link } from "gatsby"

const navlinks = [
  {
    label: "News",
    to: "/",
  },
  {
    label: "Projekte",
    to: "/projekte",
  },
  {
    label: "Über Uns",
    to: "/about",
  },
  {
    label: "LIONS Deutschland",
    href: "https://www.lions.de/",
  },
]

const Link = styled(_Link)`
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  color: inherit;
`

const match = (str) =>
  typeof window !== "undefined" && window.location.pathname === str

const LionsDrawer = ({ open, handleClose }) => {
  const theme = useTheme()

  const { allContentfulEinfacheSeite } = useStaticQuery(
    graphql`
      query {
        allContentfulEinfacheSeite {
          nodes {
            urlTitle
            title
          }
        }
      }
    `
  )

  const additionalLinks = allContentfulEinfacheSeite.nodes
    .filter(({ urlTitle }) => !excluded.has(urlTitle))
    .map(({ urlTitle, title }) => ({
      label: title,
      to: `/${urlTitle}`,
    }))

  const links = navlinks.concat(additionalLinks)

  return (
    <Drawer open={open} onClose={handleClose}>
      <div
        css={`
          width: ${theme.spacing(30)}px;
        `}
      >
        <List>
          <ListItem>
            <EmblemColor
              css={`
                width: 36px;
                height: 36px;
                margin-right: ${theme.spacing(2)}px;
              `}
            />
            <Typography variant={"h6"}>LIONS Achern</Typography>
          </ListItem>
          <Divider />
          {links.map(({ to, href, label }) =>
            to ? (
              <Link key={label} to={to}>
                <ListItem selected={match(to)} button>
                  <ListItemText primary={label} />
                </ListItem>
              </Link>
            ) : (
              <MLink key={label} href={href}>
                <ListItem button>
                  <ListItemText primary={label} />
                </ListItem>
              </MLink>
            )
          )}
        </List>
      </div>
    </Drawer>
  )
}

export default LionsDrawer
