import React from "react"
import Container from "@material-ui/core/Container"
import Toolbar from "@material-ui/core/Toolbar"
import Hidden from "@material-ui/core/Hidden"
import { graphql, useStaticQuery } from "gatsby"
import excluded from "../../../pagesExcludedFromDynamic.js"
import IconButton from "@material-ui/core/IconButton"
import Box from "@material-ui/core/Box"
import MLink from "@material-ui/core/Link/Link"
import MenuIcon from "@material-ui/icons/Menu"
import AppBar from "@material-ui/core/AppBar"
import Typography from "@material-ui/core/Typography"
import Button from "@material-ui/core/Button"
import Tabs from "@material-ui/core/Tabs"
import Tab from "@material-ui/core/Tab"
import { styled as muiStyled } from "@material-ui/core/styles"
import styled from "styled-components"
import { Link as _Link } from "gatsby"
import { rhythm } from "../../../utilities/typography"
import Logo from "../../../svg/emblem_color.svg"
import { useLocation } from "@reach/router"

const Link = styled(_Link)`
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  color: inherit;
`

const OtherLink = muiStyled(_Link)(({ selected }) => {
  return {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "inherit",
    textDecoration: selected ? "underline" : "none",
    textDecorationLine: selected ? "underline" : "none",
    textUnderlineOffset: "3px",
    textDecorationThickness: "3px",
    "&:hover": {
      textDecoration: "underline",
      textUnderlineOffset: "3px",
      textDecorationThickness: "3px",
    },
  }
})

const LinkTab = ({ to, handlePartiallyCurrent, ...props }) => {
  const handleGetProps = ({ isCurrent }) => {
    if (isCurrent && typeof handlePartiallyCurrent === "function")
      handlePartiallyCurrent()
  }

  return to ? (
    <Link to={to} getProps={handleGetProps}>
      <Tab
        {...props}
        id={`nav-tab-${props.label}`}
        aria-controls={`nav-tabpanel=${props.label}`}
      />
    </Link>
  ) : (
    <Tab
      href={props.href}
      component={"a"}
      {...props}
      id={`nav-tab-${props.label}`}
      aria-controls={`nav-tabpanel=${props.label}`}
    />
  )
}

const LoginButton = () => {
  return (
    <MLink
      href={"https://mitglieder.lions-achern.de"}
      css={`
        margin-left: ${rhythm(2)};
      `}
    >
      <Button variant={"contained"} size="small" color={"secondary"}>
        Login
      </Button>
    </MLink>
  )
}

const LionsAppBar = ({ handleClickMenu }) => {
  const { allContentfulEinfacheSeite } = useStaticQuery(
    graphql`
      query {
        allContentfulEinfacheSeite {
          nodes {
            urlTitle
            title
          }
        }
      }
    `
  )

  const otherLinks = allContentfulEinfacheSeite.nodes.filter(
    (node) => !excluded.has(node.urlTitle)
  )

  const { pathname } = useLocation()

  return (
    <>
      <AppBar
        css={`
          background: none;
        `}
        position={"sticky"}
      >
        <Toolbar
          disableGutters
          css={`
            background: ${({ theme }) => {
              return theme.colors.lci_blue
            }};
          `}
        >
          <Container maxWidth={"lg"}>
            <Box
              py={1}
              px={0}
              css={`
                display: flex;
                align-items: center;
              `}
            >
              <Hidden lgUp>
                <IconButton
                  onClick={handleClickMenu}
                  edge="start"
                  color="inherit"
                  aria-label="menu"
                >
                  <MenuIcon />
                </IconButton>
              </Hidden>
              <Logo
                css={`
                  width: ${rhythm(1.75)};
                  height: ${rhythm(1.75)};
                  margin-right: ${rhythm(1 / 2)};
                `}
              />
              <Hidden smUp>
                <Typography variant={"body1"}>LIONS Achern</Typography>
              </Hidden>
              <Box style={{ flexGrow: 1 }} />
              <Hidden mdDown={true}>
                <Tabs
                  value={pathname.substring(1)}
                  variant={"standard"}
                  aria-label={"top navigation"}
                >
                  <LinkTab label={"Neuigkeiten"} value={""} to={"/"} />
                  <LinkTab
                    value={"projekte"}
                    label={"Projekte"}
                    to={"/projekte"}
                  />
                  <LinkTab value={"about"} label={"Über uns"} to={"/about"} />
                  <LinkTab
                    label={"LIONS Deutschland"}
                    href={"https://www.lions.de/"}
                  />
                </Tabs>
              </Hidden>
              <LoginButton />
            </Box>
          </Container>
        </Toolbar>
        {otherLinks.length > 0 && (
          <Hidden mdDown={true}>
            <Toolbar
              disableGutters
              css={`
                background: white;
              `}
            >
              <Container
                maxWidth={"lg"}
                css={`
                  color: black;
                  background: white;
                  display: flex;
                  align-items: center;
                `}
              >
                <Box my={0} mx={1}>
                  <Typography>Weitere Seiten:</Typography>
                </Box>
                {otherLinks.map(({ urlTitle, title }) => (
                  <Box my={0} mx={1}>
                    <Typography>
                      <OtherLink
                        selected={pathname.substring(1) === urlTitle}
                        key={urlTitle}
                        to={"/" + urlTitle}
                      >
                        {title}
                      </OtherLink>
                    </Typography>
                  </Box>
                ))}
              </Container>
            </Toolbar>
          </Hidden>
        )}
      </AppBar>
    </>
  )
}

export default LionsAppBar
