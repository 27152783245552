import React, { useState } from "react"
import PropTypes from "prop-types"
import { rhythm } from "../../../utilities/typography"
import styled, { createGlobalStyle } from "styled-components"
import StyledLink from "../../StyledLink"
import { useTheme } from "@material-ui/core/styles"
import useMediaQuery from "@material-ui/core/useMediaQuery"
import LionsAppBar from "../../navigation/appBar"
import LionsDrawer from "../../navigation/lionsDrawer"
import Helmet from "react-helmet"

const GlobalStyle = createGlobalStyle`
  #gatsby-focus-wrapper {
    min-height: 100vh;
  }

  body {
    margin: 0;
  }
`

const Main = styled.main`
  display: flex;
  min-height: calc(
    100vh - ${(props) => props.theme.navbarHeight} -
      ${(props) => props.theme.footerHeight}
  );
  flex-grow: 1;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  padding-top: ${(props) => props.theme.navbarHeight};
  width: 100%;
`

const Footer = styled.footer`
  display: flex;
  color: ${(props) => props.theme.colors_rgba.black_medium};
  flex-direction: column;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
  max-height: ${(props) => props.theme.footerHeight};
  padding: ${rhythm(1)} ${rhythm(1)};

  @media (min-width: ${(props) => props.theme.breakpoints.large}px) {
    flex-direction: row;
  }

  & > * {
    margin: 0 ${rhythm(1)};
  }
`

const MainLayout = ({ children }) => {
  const theme = useTheme()
  const matches = useMediaQuery(theme.breakpoints.down("md"))

  const [open, setOpen] = useState(false)
  const toggleOpen = () => {
    setOpen(!open)
  }

  return (
    <>
      <Helmet></Helmet>
      <GlobalStyle />
      <LionsAppBar
        handleClickMenu={toggleOpen}
      />
      {matches && (
        <LionsDrawer open={open} handleClose={() => setOpen(false)} />
      )}
      <div
        css={`
          width: 100vw;
          max-width: 100%;
          min-height: 100vh;
          display: flex;
          margin-top: -${(props) => props.theme.navbarHeight};
          flex-direction: column;
        `}
      >
        <Main>{children}</Main>
        <Footer>
          <StyledLink href="mailto:lionsclub-achern@t-online.de">
            lionsclub-achern@t-online.de
          </StyledLink>
          <StyledLink to="/impressum">Impressum</StyledLink>
        </Footer>
      </div>
    </>
  )
}

MainLayout.propTypes = {
  children: PropTypes.node.isRequired,
}

export const Content = styled.div`
  padding-top: ${rhythm(1)};
  padding-bottom: ${rhythm(2)};
  padding-left: ${rhythm(1 / 2)};
  padding-right: ${rhythm(1 / 2)};

  @media (min-width: ${(props) => props.theme.breakpoints.medium}px) {
    padding-top: ${rhythm(2)};
    padding-bottom: ${rhythm(2)};
    padding-left: ${rhythm(2)};
    padding-right: ${rhythm(2)};
  }
`

export default MainLayout
