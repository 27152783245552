import React from "react"
import styled, { css } from "styled-components"
import { Link } from "gatsby"

const linkStyle = css`
  color: inherit;
  text-decoration: underline;
  cursor: pointer;
`

const StyledAnchor = styled.a`
  ${linkStyle}
`

const StyledGatsbyLink = styled(Link)`
  ${linkStyle}
`

const StyledLink = ({ to, href, children }) =>
  to ? (
    <StyledGatsbyLink to={to}>{children}</StyledGatsbyLink>
  ) : (
    <StyledAnchor href={href}>{children}</StyledAnchor>
  )

export default StyledLink
